import React, {useEffect, useState} from 'react';
import {Box, Typography, CircularProgress, IconButton, useMediaQuery, useTheme} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import './Meetings.css';
import axios from "axios";
import config from "../config";
import {useNavigate} from "react-router-dom";

const numberOfRecordsPerPage = 25;

const Meetings = () => {
    const [meetings, setMeetings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchMeetings(currentPage);
    }, [currentPage]);

    const fetchMeetings = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meetings-weekly-view`, {
                params: {page}
            });
            const data = response.data;
            if (data.menuMeetings) {
                setMeetings(data.menuMeetings);
                setTotalPages(data.totalPages);
                setTotalRecords(data.totalRecords);
            }
        } catch (error) {
            console.error('Error fetching menu items:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMeetingClick = (meetingId) => {
        // navigate('/product/meeting', {state: {selectedMeetingId: meetingId}});
        navigate(`/product/meeting/${meetingId}`);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const renderTableHeader = () => (
        <Box className="table-header">
            <Box className="header-content">
                <Typography className="header-text meeting">Meeting</Typography>
                <Typography className="header-text date">Date</Typography>
                <Typography className="header-text meeting-time">Time</Typography>
                <Typography className="header-text duration">Duration</Typography>
            </Box>
            {!isMobile && renderPaginationControls()}
        </Box>
    );

    const renderPaginationControls = () => (
        <Box className="pagination-controls">
            <Typography className="pagination-text">
                {`${(currentPage - 1) * numberOfRecordsPerPage + 1}-${Math.min(currentPage * numberOfRecordsPerPage, totalRecords)} of ${totalRecords}`}
            </Typography>
            <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
            >
                <ChevronLeft/>
            </IconButton>
            <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
            >
                <ChevronRight/>
            </IconButton>
        </Box>
    );

    const renderMeetingRow = (meeting, index) => (
        <Box
            key={index}
            className="table-row"
            onClick={() => handleMeetingClick(meeting.id)}
        >
            {isMobile ? (
                <Box className="mobile-row-content">
                    <Typography className="row-text name">{meeting.name}</Typography>
                    <Box className="mobile-row-details">
                        <Typography className="row-text date">{meeting.date}</Typography>
                        <Typography className="row-text row-time">{meeting.time}</Typography>
                        <Typography className="row-text duration">{meeting.duration}</Typography>
                    </Box>
                </Box>
            ) : (
                <>
                    <Typography className="row-text name">{meeting.name}</Typography>
                    <Typography className="row-text date">{meeting.date}</Typography>
                    <Typography className="row-text row-time">{meeting.time}</Typography>
                    <Typography className="row-text duration">{meeting.duration}</Typography>
                </>
            )}
        </Box>
    );

    return (
        <Box className="table-container">
            {renderTableHeader()}
            {loading ? (
                <Box className="loading-container">
                    <CircularProgress/>
                </Box>
            ) : meetings.length === 0 ? (
                <Box className="table-header center-align">
                    <Typography className="no-meetings-text">
                        Hooray!! You have no meetings
                    </Typography>
                </Box>
            ) : (
                <>
                    {meetings.map((week) => (
                        <React.Fragment key={week.week}>
                            <Box className="table-header center-align">
                                <Typography className="week-text">{week.week}</Typography>
                            </Box>
                            {week.items.map(renderMeetingRow)}
                        </React.Fragment>
                    ))}
                    {isMobile && (
                        <Box className="mobile-pagination">
                            {renderPaginationControls()}
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default Meetings;