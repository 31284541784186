import React, {useCallback, useMemo} from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import {Box, Typography} from '@mui/material';
import './css/AnalyticsSpeakerRatioChart.css';
import CustomTooltip from "../../components/customtooltip/CustomTooltip";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};

const AnalyticsSpeakerRatioChart = ({historicalData}) => {

    const chartData = useMemo(() => {
        const groupedData = historicalData.reduce((acc, item) => {
            const date = formatDate(item['meta.meetingDate']);
            if (!acc[date]) acc[date] = [];
            acc[date].push(item);
            return acc;
        }, {});

        const sortedDates = Object.keys(groupedData).sort((a, b) => {
            const [dayA, monthA] = a.split('/').map(Number);
            const [dayB, monthB] = b.split('/').map(Number);
            return (monthA - monthB) || (dayA - dayB);
        });

        return sortedDates.flatMap(date =>
            groupedData[date].map((meeting, index) => ({
                date: groupedData[date].length > 1 ? `${date} (${index + 1})` : date,
                ...meeting.ratio.reduce((acc, speaker) => {
                    acc[speaker.speakerName || `Speaker ${speaker.speakerId}`] = speaker.speakerRatio;
                    return acc;
                }, {}),
                meta: {
                    speakerCount: meeting.ratio.length,
                    meetingId: meeting['meta.meetingId'],
                    meetingName: meeting['meta.meetingName']
                },
            }))
        );
    }, [historicalData]);

    const speakers = useMemo(() => {
        const allSpeakers = new Set();
        chartData.forEach(data => {
            Object.keys(data).forEach(key => {
                if (key !== 'meta' && key !== 'date') {
                    allSpeakers.add(key);
                }
            });
        });
        return Array.from(allSpeakers);
    }, [chartData]);

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#00C49F', '#FFBB28', '#FF8042'];


    const handleTooltipClick = useCallback((meetingData) => {
        window.open(`/product/meeting/${meetingData.meta.meetingId}`, '_blank');
    }, []);

    return (
        <Box className="analytics-speaker-ratio-chart">
            <Typography variant="h6">Speaker Ratio</Typography>
            {chartData.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={chartData}
                        barSize={30}
                        barGap={2}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                            dataKey="date"
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => value}
                        />
                        <YAxis
                            tickFormatter={(value) => `${Math.round(value * 100)}%`}
                            domain={[0, 1]}
                            ticks={[0, 0.25, 0.5, 0.75, 1]}
                            tick={{fontSize: 12}}
                        />
                        <Tooltip
                            content={({active, payload, label, coordinate}) => (
                                <CustomTooltip
                                    active={active}
                                    payload={payload}
                                    label={label}
                                    coordinate={coordinate}
                                    onClick={handleTooltipClick}
                                    valueFormatter={(value) => `${(value * 100)}%`}
                                />
                            )}
                        />
                        <Legend/>
                        {speakers.map((speaker, index) => (
                            <Bar
                                key={speaker}
                                dataKey={speaker}
                                stackId="a"
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <Box className="empty-data-message">
                    <Typography variant="body1">
                        No data available for speaker ratio chart
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default AnalyticsSpeakerRatioChart;