import React from 'react';
import PropTypes from 'prop-types';
import './CustomTooltip.css';

const CustomTooltip = ({ active, payload, label, onClick, valueFormatter, coordinate }) => {
    if (active && payload && payload.length) {
        const meetingData = payload[0].payload;
        const handleClick = (e) => {
            e.stopPropagation(); // Prevent the chart's onClick from firing
            onClick && onClick(meetingData);
        };

        const style = coordinate ? {
            left: coordinate.x,
            top: coordinate.y
        } : {};

        return (
            <div className="custom-tooltip" style={style} onClick={handleClick}>
                <p className="label">{label}</p>
                <p className="meeting-name">{meetingData.meta.meetingName}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: entry.color, marginBottom: '2px' }}>
                        {`${entry.name}: ${valueFormatter ? valueFormatter(entry.value) : entry.value}`}
                    </p>
                ))}
                <p className="click-info">Click to open meeting in new tab</p>
            </div>
        );
    }
    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string,
    onClick: PropTypes.func,
    valueFormatter: PropTypes.func,
    coordinate: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    })
};

export default CustomTooltip;