import React from 'react';
import {NavLink} from 'react-router-dom';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {Box, ListItemButton} from '@mui/material';
import './Sidebar.css';
import {getRandomLogoPair} from "../utils";
import SettingsPanel from "./settings/SettingsPanel";

const fontFamilies = ['Roboto Slab', 'Raleway', 'Montserrat', 'Playfair Display', 'Poppins'];
const font_index = 2;
const pair = getRandomLogoPair();

const sidebarItems = [
    {
        to: '/product/home',
        icon: <img src={pair.logo} alt="Elevaide Logo" className="elevaide-logo-image"/>,
        text: 'Home'
    },
    // {to: '/product/meeting-chat', icon: <MarkChatUnreadIcon/>, text: 'Chat'},
    {to: '/product/analytics', icon: <AssessmentIcon/>, text: 'Analytics'},
    {to: '/product/company', icon: <PeopleAltIcon/>, text: 'Company'},
    {to: '/product/upload', icon: <CloudUploadIcon/>, text: 'Upload'},
];

const Sidebar = () => {
    const [settingsPanelToggle, setSettingsPanelToggle] = React.useState(() => () => {
    });

    return (
        <>
            <Box className="styled-sidebar">
                <List className="sidebar-list">
                    {sidebarItems.map((item, index) => (
                        <ListItemButton component={NavLink} to={item.to} key={index} className="styled-list-item">
                            <ListItemIcon className="styled-list-item-icon">
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.text}
                                primaryTypographyProps={{
                                    variant: 'caption',
                                    fontFamily: fontFamilies[font_index],
                                }}
                                className="styled-list-item-text"
                            />
                        </ListItemButton>
                    ))}
                    <ListItemButton
                        onClick={settingsPanelToggle}
                        className="styled-list-item settings-item"
                    >
                        <ListItemIcon className="styled-list-item-icon">
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary="Settings"
                            primaryTypographyProps={{
                                variant: 'caption',
                                fontFamily: fontFamilies[font_index],
                            }}
                            className="styled-list-item-text"
                        />
                    </ListItemButton>
                </List>
            </Box>
            <SettingsPanel setToggleFunction={setSettingsPanelToggle}/>
        </>
    );
};

export default Sidebar;
